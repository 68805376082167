.ReactModal__Overlay {
    background-color: rgba(47, 255, 0, 0);
    opacity: 1;
    width: 100%;
    height: 100%;
    position: inherit;
    transform: translate(+0%,+0%);
    transition: opacity 400ms ease-in-out;
}

.ReactModal__Body--open {
    overflow: hidden;
    background-color: rgba(52,53,65, 1);
}

.portal {
    position: absolute;
    left: 50%; /* set the left position to 50% */
    top: 50%; /* set the top position to 50% */
    transform: translate(-50%,-50%); /* center the element using translate */
    color: aliceblue;
    background-color: rgba(52,53,65);
    z-index: 1000;
    border-radius: 10px;
    width: auto;
    height: auto;
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }

input {
    border-radius: 4px;
    background-color: #40414f;
    font-size: 100%;
    color: aliceblue;
    padding: 10px;
    margin: 1%;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
    background-color: rgba(52,53,65, 0);
}

.ReactModalBody{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: auto;
    height: auto;
    background-color: rgba(52,53,65);
    overflow: hidden;
}

.ReactModalBody-header{
    background-color: #40414f;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    text-align: center;
    font-size: 150%;
    width: auto;
    height: auto;
    width: 100%;
    height: 100%;
}

.ReactModalBody-footer{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    text-align: right;
    position: relative;
    overflow: hidden;
    width: auto;
    height: auto;
    background-color: #40414f;
    border-radius: 4px;
    font-size: 100%;
}

.HomeBody{
    display: grid;
    grid-template-columns: [left-padding] 15% [menu] 25% [filler] auto [right-padding] 5%;
    grid-template-rows: [whole] 100vh;
}

.item1{
    grid-template-columns: [left-padding] 10% [center] auto [right-padding] 10%;
    grid-template-rows: [top-padding] 20% [center] auto [bottom-padding] 20%;
    grid-column: menu;
    grid-row: auto;
    display: grid;
}

.item1 div{
    border-radius: 10px;
    grid-column: center;
    grid-row: center;
    text-align: center;
    grid-template-columns: auto;
    grid-template-rows: [header] 15% [body] auto [footer] auto;
    background-color: #40414f;
}

.item1-header{
    padding-top: 20px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    text-decoration: underline;
    grid-row: header;
    color:aliceblue
}

.item1 button{
    border-radius: 10px;
    width: 30%;
    height: 5%;
}

.item2{
    display: inline-block;
    position: relative;
}

.container { 
    display: inline-block;
    position: absolute;
    left: 50%; /* set the left position to 50% */
    top: 50%; /* set the top position to 50% */
    transform: translate(-50%,-50%);
    opacity: 1;
    z-index: -1;
}
  
.box {
    z-index: -10;
    opacity: 1;
    transform: translate(0%,0%);        
}

.overlay {
    z-index: 1;
    transform: translate(27%,-725px); 
    height: 0px;
}


html, body {
    height: 100%; /* make sure that the html and body elements take up the full height of the viewport */
    margin: 0; /* remove any default margins */
    padding: 0; /* remove any default padding */
    overflow-x: hidden;
  }
  
.page-wrapper {
    min-height: 100%; /* set the minimum height of the wrapper to 100% */
    position: relative; /* set the wrapper to be a positioned element */
}

.footer {
    position: relative; /* set the footer to be a positioned element */
    bottom: 0; /* position the bottom of the footer at the bottom of the page */
    width: 100%; /* make the width of the footer 100% of the page */
    height: 50px; /* set the height of the footer */
}