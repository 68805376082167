.gameover__container {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: rgba(52,53,65);
}

.gameover__container > :nth-child(1) > .user__name {
    color: gold;
}

.gameover__container > :nth-child(2) > .user__name {
    color: silver;
}

.gameover__container > :nth-child(3) > .user__name {
    color: #CD7F32;
}

.user__entry {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 5px;
    padding: 5px;
}

.user__place {
    color: white;
}

.user__name {
    color:lightgray;
    overflow: hidden;
}

.user__correct {
    color: green;
}

.user__incorrect {
    color: red;
}

