@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.chat {
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.chat__main {
  height: 100%;
  flex: 1;
  background-color: lightskyblue;
}

.chat__header {
  margin: 30px 0 20px 0;
  background-color: lightskyblue;
}

.chat__mainHeader {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: lightskyblue;
}

.message__container {
  width: 100%;
  height: 300px;
  background-color: lightslategray;
  padding: 20px;
  overflow-y: scroll;
}

.message__container > * {
  margin-bottom: 10px;
}

.chat__footer {
  padding: 10px;
  background-color: lightskyblue;
  height: 40px;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form > input {
  font-size: 15px
}

.form > button {
  margin-left: 5px;
  font-size: 12px
}

.message {
  width: 100%;
  height: 80%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px;
}

.sendBtn {
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  color: #eae3d2;
  cursor: pointer;
}

.sendBtn:hover {
  background-color: rgb(129, 201, 129);
}

.message__recipient {
  display: flex;
  flex-direction: column;
  width: auto;
  align-self: center;
  background-color: lightgrey;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-right: auto;
  font-size: 15px;
  align-self: left;
}

.message__sender {
  display: inline-block;
  width: auto;
  align-self: center;
  background-color: lightblue;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
  align-self: right;
}

.message__sender > p {
  word-wrap: break-word;
}

.message__recipient > p {
  word-wrap: break-word;
}

.message__chats {
  display: flex;
  flex-direction: row;
}

.message__chats > p {
  font-size: 10px;
}

.sender__name {
  text-align: right;
  align-self: right;
}

.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}